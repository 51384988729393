<template>
    <div id="tabBar">
        <ul id="item">
            <li :class="currentPage.active===1?'isActive':''"><router-link to="/">首页</router-link></li>
            <li :class="currentPage.active===2?'isActive':''" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @click="handleClickProduct">
                <router-link to="/product/1">产品</router-link>
                <ul id="secondMenu"  @click="handleMouseLeave" v-show="currentPage.showMenu">
                    <li><router-link to="/product/1">第一实验室</router-link></li>
                    <li><router-link to="/product/2">毫米波通信</router-link></li>
                    <li><router-link to="/product/3">毫米波感知</router-link></li>
                </ul>
            </li>
            <!-- <li :class="currentPage.active===3?'isActive':''">
                <router-link to="/human-resource">人力资源</router-link>
            </li> -->
            <li :class="currentPage.active===4?'isActive':''">
                <router-link to="/news">新闻中心</router-link>
            </li>
            <li :class="currentPage.active===5?'isActive':''">
                <router-link to="/contact-us">联系我们</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name:"TabBar",
    created(){
        this.onRouteChanged()
    },
    data(){
        return{
            currentPage:{
                active:'',
                showMenu:false
            }
        }
    },
    methods:{
        onRouteChanged () {
            let that = this
            that.path  = that.$route.path
            switch (this.path.split('/')[1]) {
                case '':
                    this.handleMouseLeave()
                    this.$set(this.currentPage,'active',1)
                    break;
                case 'product':
                    this.$set(this.currentPage,'active',2)
                    break;
                case 'solution-product':
                    this.handleMouseLeave()
                    this.$set(this.currentPage,'active',2)
                    break;
                case 'human-resource':
                    this.handleMouseLeave()
                    this.$set(this.currentPage,'active',3)
                    break;
                case 'news':
                    this.handleMouseLeave()
                    this.$set(this.currentPage,'active',4)
                    break;
                case 'contact-us':
                    this.handleMouseLeave()
                    this.$set(this.currentPage,'active',5)
                    break;
                default:
                    this.handleMouseLeave()
                    this.$set(this.currentPage,'active',1)
                    break;
            }
        },
        handleClick(){
            this.$router.push({path:'/solution-product/1'})
        },
        handleMouseEnter(){
            this.$set(this.currentPage,'showMenu',true)
        },
        handleMouseLeave(){
            this.$set(this.currentPage,'showMenu',false)
        },
        handleClickProduct(){
            this.$set(this.currentPage,'showMenu',!this.currentPage.showMenu)
        }
    },
    watch:{
        '$route': 'onRouteChanged'
    }
}
</script>

<style scoped>
    #tabBar{
        width: 100%;
        background-color: #2e5497;
        position:relative;
        opacity: 99;
    }
   #item{
        max-width: 1200px;
        margin:auto;
        display: flex;
        padding-left: 0;
   }
   
   #item > li{
    display: inline-block;
    width: 25%;
    text-align: center;
   }
   #item > li > a{
    font-size: 20px;
    color: white;
    line-height: 60px;
    text-decoration: none;
    display: block;
   }
   @media (max-width:1024px) {
    #item > li > a{
        font-size: 16px;
    }
    #secondMenu > li > a{
        font-size: 16px !important;
    }
   }
   @media (max-width:640px) {
    #item > li > a{
        font-size: 12px;
    }
    #secondMenu > li > a{
        font-size: 12px !important;
    }
   }
   .isActive{
    background-color: #4BACC6;
   }
   #item > li:hover{
    background-color: #4BACC6;
   }
   #secondMenu{
    display: flex;
    flex-direction: column;
    position:absolute;
    width: 25%;
    max-width: 300px;
    background-color: #4BACC6;
    list-style: none;
    padding-left: 0;
    z-index: 10;
   }
   #secondMenu > li > a{
    display: block;
    text-align: center;
    font-size: 20px;
    color: white;
    line-height: 60px;
    text-decoration: none;
   }
   #secondMenu > li:hover{
    background-color: rgb(41, 137, 151);
   }
</style>